export interface OptionalRoute {
  path: string
  componentName: string
  status?: number
  privateRouter: boolean
  role?: 'ADMIN' | 'USER'
}

export const routes: OptionalRoute[] = [
  // publicRouter
  {
    path: '/',
    componentName: 'login/index',
    privateRouter: false
  },
  {
    path: '/admin-login',
    componentName: 'login/admin-login',
    privateRouter: false
  },
  { // Admin login system support 
    path: '/shadow-log',
    componentName: 'login/shadow-log',
    privateRouter: false
  },
  {
    path: '/authentication',
    componentName: 'login/_authentication',
    privateRouter: false,
    role: 'USER'
  },
  // privateRouter
  {
    path: '/chat',
    componentName: 'chat/index',
    privateRouter: true,
    role: 'USER'
  },
  {
    path: '/dashboard',
    componentName: 'dashboard/index',
    privateRouter: true,
    role: 'USER'
  },
  {
    path: '/lead',
    componentName: 'lead/index',
    privateRouter: true,
    role: 'USER'
  },
  {
    path: '/campaign',
    componentName: 'campaign/index',
    privateRouter: true,
    role: 'USER'
  },
  {
    path: '/campaign/:id',
    componentName: 'campaign/detail/index',
    privateRouter: true,
    role: 'USER'
  },
  {
    path: '/settings',
    componentName: 'settings/index',
    privateRouter: true,
    role: 'USER'
  },
  {
    path: '/settings/oauth-redirected',
    componentName: 'settings/index',
    privateRouter: true,
    role: 'USER'
  },
  {
    path: '/pricing',
    componentName: 'pricing/index',
    privateRouter: true,
    role: 'USER'
  },
  {
    path: '/admin/user',
    componentName: 'user/index',
    privateRouter: true,
    role: 'ADMIN'
  },
  {
    path: '/deeplink',
    componentName: 'deeplink/index',
    privateRouter: false
  },
  {
    path: '/unsubscribe',
    componentName: 'unsubscribe/index',
    privateRouter: false
  },
  // The NotFoundPage is a catch-all and nothing should be below it.
  {
    path: '*',
    componentName: 'NotFoundPage',
    status: 404,
    privateRouter: false
  },
  {
    path: '/privacyPolicy',
    componentName: 'LegalDocuments/PrivacyPolicy/index',
    privateRouter: false,
  },
]
